<template>
  <div class="terms">
      <loading :active.sync="isLoading"
               :is-full-page="fullPage"></loading>

    <div class="row ">
      <div class="col-md-12">
        <div class="text-center mt-3 mb-3">
          <h6>Cover Summary</h6>
        </div>
        <div class="p-2  rounded">

          <div class="shadow-lg bg-white">
            <div class="p-5">

              <div style="border: 1px solid #6F6F6F;border-radius: 6px;" class="p-3">

                <h5 style="margin-bottom: 20px;">Personal Details</h5>

                <table class="table">
                  <tr>
                    <td>Full Names</td>
                    <td><strong>{{ coverDetails.fullName }}</strong></td>
                    <td>Phone Number</td>
                    <td><strong>{{ coverDetails.phoneNumber }}</strong></td>
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    <td><strong>{{ coverDetails.emailAddress }}</strong></td>
                    <td>ID No. / Passport No.</td>
                    <td><strong>{{ coverDetails.identificationNumber  }}</strong></td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td><strong>{{ coverDetails.gender }}</strong></td>
                    <td>KRA Pin</td>
                    <td><strong>{{ coverDetails.kraPin }}</strong></td>
                  </tr>
                  <tr>
                    <td>Starting Date</td>
                    <td><strong>{{ coverDetails.preferredStartDate }}</strong></td>

                  </tr>
                </table>
              </div>

              <div style="border: 1px solid #6F6F6F;border-radius: 6px;" class="mt-5 p-3">

                <h5 style="margin-bottom: 20px;">Vehicle Details</h5>

                <table class="table">
                  <tr>
                    <td>Date</td>
                    <td><strong>{{ coverDetails.scheduleDetails.dateOfInstallation }}</strong></td>
                    <td>Time</td>
                    <td><strong>{{ coverDetails.scheduleDetails.timeOfInstallation }}</strong></td>
                    <td>Location</td>
                    <td><strong>{{ coverDetails.scheduleDetails.locationOfInstallation }}</strong></td>
                  </tr>
                </table>

              </div>

              <div class="mt-5">
                <label > <input type="checkbox" v-model.trim="coverDetails.termsAndCondition"> &nbsp;&nbsp;  <strong> By Confirming this you agree to our Terms and Conditions.</strong> </label>
              </div>


              <div class="row mt-3 ml-2 mr-2 pb-3">
                <div class="col-4">

                </div>
                <div class="col-4">
                  <button @click="validateBeforeSubmit" style="background-color:#E42B1A;color:#fff;" type="button"  class=" btn  btn-lg  quote-btn quotation-button">  <strong>Confirm </strong> </button>
                </div>
                <div class="col-4">

                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

      <modal :resizable="resizable"
             :adaptive="adaptive"
             :draggable="draggable" name="success-checkout" height="350">
          <div class="p-5 text-center success-checkout">
              <div class="mb-5">
                  <h4 class="text-primary">You’ve successfully purchased a policy.</h4>
              </div>
              <div class="mb-5">
                Check your email for further instructions
              </div>

              <div style="border: 1px solid #6F6F6F;border-radius: 6px;" class="p-3">
                  <RouterLink style="width: 100%;font-weight: bolder;background-color:#E42B1A !important; " to="/login" class="btn  btn-secondary btn-block quote-btn" > Login </RouterLink>
              </div>
          </div>
      </modal>


  </div>
</template>

<script>

    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import { required,requiredIf} from 'vuelidate/lib/validators'

    import carBrands from "./car_brands";

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import { mapActions } from "vuex";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
          place_desc:"",
          isLoading: false,
          fullPage: true,
          resizable: true,
          adaptive: true,
          draggable: true,
          coverDetails:{
              quoteId:"",
              preferredStartDate:"",
              kraPin:"",
              postalCode:"",
              poBoxNumber:"",
              vehicleReg:"",
              fullName:"",
              phoneNumber:"",
              idType:"",
              identificationNumber:"",
              emailAddress:"",
              gender:"",
              termsAndCondition:"",
              locationOfInstallation:"",
              scheduleDetails:{
                dateOfInstallation: localStorage.getItem("scheduleDate"),
                timeOfInstallation:localStorage.getItem("scheduleTime"),
                locationOfInstallation: localStorage.getItem("scheduleLocation")
              }
          },
          car_brands:carBrands,
          markers: {
              position: { lat: -1.292066, lng: 36.821946  }
          },
          center: { lat: -1.292066, lng: 36.821946 },
          identification_front_side_file:"",
          identification_back_side_file:"",
          kra_document_file:"",
          logbook_file:"",
          upload_documents_error_message:"",

      };
    },
      computed: {

      },
      components: {
          FormWizard,
          DatePicker,
          TabContent,
          Loading
      },

      mounted(){
            this.getQuoteData();
      },
    methods:{
        ...mapActions(["getQuoteDetails","finalizeQuoteDetails"]),
        getQuoteData(){
            let self = this;
            this.isLoading = true;

            this.getQuoteDetails({id: this.$route.params.quoteId}).then((quote) => {
                self.isLoading = false;

                this.coverDetails.fullName = quote.fullName;
                this.coverDetails.phoneNumber = quote.phoneNumber;
                this.coverDetails.emailAddress = quote.emailAddress;
                this.coverDetails.identificationNumber = quote.identificationNumber;
                this.coverDetails.postalCode = quote.postalCode;
                this.coverDetails.poBoxNumber = quote.poBoxNumber;
                this.coverDetails.kraPin = quote.kraPin;
                this.coverDetails.gender = quote.gender;
                this.coverDetails.preferredStartDate = quote.preferredStartDate;
                this.coverDetails.quoteId = quote._id;


            }).catch(error => {
                self.isLoading = false;
                console.log(error);
            })
        },

        validateBeforeSubmit(){
            if(this.coverDetails.termsAndCondition){
                let self = this;
                self.isLoading = true;

                self.finalizeQuoteDetails({quoteId: self.coverDetails.quoteId}).then((response) => {
                  self.isLoading = false;

                  self.$modal.show('success-checkout');

                }).catch(error => {
                  self.isLoading = false;
                  console.log(error);
                })
            } else {
              this.$toast.open({
                message: "Agree to terms and conditions to proceed",
                type: 'warning',
                duration:5000,
                position:"top-right"
              });
            }
        }
    }
  }
</script>

<style >

    table tr th{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    fieldset.custom-border {
        border: 1px groove #ddd !important;
        padding: 0 1.4em 1.4em 1.4em !important;
        margin: 0 0 1.5em 0 !important;
        -webkit-box-shadow: 0px 0px 0px 0px #000;
        box-shadow: 0px 0px 0px 0px #000;
    }

    legend.custom-border {
        width: auto;
        padding: 0 10px;
        border-bottom: none;
    }

    label{
        font-size: 12px;
    }


    .table th, .table td{
      padding: 5px !important;
      font-size: 12px;
    }
</style>
